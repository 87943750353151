<template>
  <div class="contact">
    <h3>
      {{ $t('default.Inquiryforcollaboration') }}
    </h3>
    <p>
      {{ $t('default.ContactText1') }}
    </p>
    <p>
      {{ $t('default.IfContact') }}
    </p>
    <p>
      1. Zhu Z, Shi D, Guankai P, et al. Retinal age gap as a predictive biomarker for mortality risk. British Journal of Ophthalmology. 2023;107(4):547-54.
    </p>
    <p>
      2. Zhu Z, Chen Y, Wang W, et al. Association of retinal age gap with arterial stiffness and incident cardiovascular disease. Stroke, 2022; 53(11):3320-3328.
    </p>
    <p>
      3. Zhu Z, Hu W, Chen R, et al. Retinal age gap as a predictive biomarker of stroke risk. BMC medicine. 2022;20(1):466.
    </p>
    <p>
      4. Hu W, Wang W, Wang Y, et al. Retinal age gap as a predictive biomarker of future risk of Parkinson's disease. Age and Ageing. 2022;51(3):afac062.
    </p>
    <p>
      5. Zhang S, Chen R, Wang Y, et al. Association of Retinal Age Gap and Risk of Kidney Failure: A UK Biobank Study. American Journal of Kidney Diseases. 2022 Dec 5.
    </p>
    <h3>
    {{ $t('default.Disclaimer') }}
    </h3>
    <p>
    {{ $t('default.DisclaimerText') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style lang="less">
.contact {
  text-align: left;
  width: 50%;margin: 50px auto;
  p {
    font-size: 20px;
    margin: 15px 0;
    text-align: left;
  }
  padding: 0 0 100px;
}
</style>