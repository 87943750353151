<template>
  <div class="insurance">
    <div class="banner">
      <div class="banner-box">
        <h1>Enhance Client Engagement
        </h1>
        <el-button class="home-btn" @click="$router.push('/platform')" type="primary">Try it</el-button>
      </div>
      <img class="background" src="@/assets/insurance_banner.png" alt="">
    </div>
    <el-card>
    <el-tabs tab-position="left"  type="border-card">
      <el-tab-pane label="New customers onboarding">
        <h1>Appeal and set up a new customer in your system</h1>

        <p style="margin: 20px 0">
          Add ageing risk to your yearly insurer check-in, creating competitive packages that meet your clients’ needs.
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Let objective, real-time health and wellness data drive customer’s decision
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Improve customer experience and engagement with digitization onboarding
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Appeal to a millennial audience
        </p>
      </el-tab-pane>
      <el-tab-pane label="Personalized service">
        <h1>Reach insurance customers with targeted pricing, offers, and messages at the right time.</h1>
        <p style="margin: 20px 0">
          Add personalized ageing risk monitoring to reaching insurance customers with targeted pricing, offers, and messages at the right time. .
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Let comprehensive and real-time wellness data provided by clients drive your decisions
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Reduce the risk of renewals and upgrades
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Improve customer experience with individualized monitoring
        </p>
      </el-tab-pane>
      <el-tab-pane label="Risk management">
        <h1>Improve risk assessment for life insurance policies using real-time health data and <span style="text-transform: capitalize;">RetinaAGE</span> score.</h1>

        <p style="margin: 20px 0">Accelerate onboarding and enhance straight-through processing.
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Quickly flag risky clients using <span style="text-transform: capitalize;">RetinaAGE</span> ageing score
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Inform underwriting and risk assessment with objective, client- provided, real-time health data
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Extend straight-through processing to more candidates and  create a seamless, digital experience
        </p>
        <p>Bolster questionnaires.
        </p>
      </el-tab-pane>
      <el-tab-pane label="Wellness program">
        <h1>Increase wellness program engagement and empower your clients to take control of their health.</h1>
        <p style="margin: 20px 0">
        Add real-time ageing risk monitoring to your wellness programs to boost client-engagement and program success.
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Personalize wellness programs to suit your clients’ needs
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Allow clients to easily track overall wellness with our accelerated ageing risk score
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Incentivize healthy behaviors and reward clients for health improvements
        </p>
        <p>
          <img src="@/assets/healthcare_icon_0.png" alt="">Encourage clients to monitor wellbeing and prevent onset of age-related health issues
        </p>
      </el-tab-pane>
    </el-tabs>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style lang="less">
.insurance {
  padding: 0 0 100px;
  .home-btn {
    width: 200px;
    font-weight: 800;
    border-radius: 50px;
    height: 50px;
    font-size: 23px;
    box-shadow: 0 10px 15px #333 ;
  }
  .banner {
    top: -80px;
    color: white;
    width: 100%;
    position: relative;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 40%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }
  }
  .el-card {
    overflow: hidden;
    width: 1400px;
    margin: 20px auto;
    padding: 0 0;

  }
  .el-card__body {
    padding: 0 0 ;
  }
  .el-tabs--left, .el-tabs--right {
  }
  .el-tabs__item  {
    font-size: 22px;
    height: 80px;
    line-height: 80px;
    text-align: center!important;
  }
  .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    background: #F9F9FC;
    color: #000;
  }
  .el-tabs {
    background: #F9F9FC;
    margin: 0 0;
  }
  .el-tabs__header {
    background: #F1F3FA;
  }
  .el-tab-pane {
    text-align: left;
    p {
      line-height: 45px;
      font-size: 20px;
      img {
        margin-right: 10px;
      }
    }
  }
  .step-img {
    width: 80%
  }
}
</style>