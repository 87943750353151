<template>
<div>
<div v-if="false">
        RetinaAGE website is currently unavailable for regular maintenance.
  If you require assistance and collaboration, please contact us via email at retinalage2023@gmail.com
</div>
<div v-else id="app"
  :style="`transform: scale(${this.scale});height: ${this.height}px`"
  >
  <el-menu :default-active="$route.path" class="el-menu-demo header" mode="horizontal"
  :router="true"
  v-if="$route.path !== '/report'">
  <el-menu-item
  style="width: 300px;text-align: left;padding-left: 40px">
    <h1>Retina<span style="border: 0;color:#4C84FF">AGE</span>.com</h1>
  </el-menu-item>
  <el-menu-item index="/" route="/"  ><span>{{$t('default.Home')}}</span></el-menu-item>
  <el-menu-item index="/platform" route="/platform"><span>{{$t('default.Platform')}}</span></el-menu-item>
  <el-menu-item index="/technology" route="/technology"><span>{{$t('default.Technology')}}</span></el-menu-item>
  <el-menu-item index="/research" route="/research"><span>{{$t('default.Research')}}</span></el-menu-item>
  <!-- <el-submenu index="/solutions">
    <template slot="title">
      <span style="" class="menu-item">Solutions</span> </template>
    <el-menu-item index="/wellness" route="/wellness">
      <el-button style="width: 200px;" :type="$route.path==='/wellness' ?'primary': ''" >Wellness</el-button> </el-menu-item>
    <el-menu-item index="/insurance" route="/insurance">
    <el-button style="width: 200px;" :type="$route.path==='/insurance' ?'primary': ''" >Insurance</el-button>
    </el-menu-item>
    <el-menu-item index="/healthcare_service" route="/healthcare_service" >
    <el-button style="width: 200px;" :type="$route.path==='/healthcare_service' ?'primary': ''">Healthcare service</el-button>
    </el-menu-item>
  </el-submenu> -->
  <el-menu-item class="contact-btn">
    <el-button @click="$router.push('/contact')" style="width: 200px;background: #4C84FF" type="primary">{{$t('default.Contact')}}</el-button>
  </el-menu-item>
  <div class="f-r">
    <el-dropdown @command="changeLang">
      <span class="el-dropdown-link">
        {{langMap[lang]}}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="chs">简体中文</el-dropdown-item>
        <el-dropdown-item command="en">English</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</el-menu>
<router-view/>
<el-row class="home-footer" v-if="$route.path !== '/report'">
  <div class="footer-item">
    <h1 style="font-size: 30px">
      Retina<span style="color:#4C84FF">AGE</span>.com
    </h1>
    <p>
      {{$t('default.Copyright')}}
    </p>
    <p>
      {{$t('default.AllRightReserved')}}
    </p>
 </div>
  <div class="footer-item">
    <h1 style="cursor: pointer" @click="$router.push('/platform')">
      {{$t('default.Platform')}}
    </h1>
 </div>
  <div class="footer-item">
    <h1 style="cursor: pointer" @click="$router.push('/technology')">
      {{$t('default.Technology')}}
    </h1>
 </div>
  <div class="footer-item">
    <h1 style="cursor: pointer" @click="$router.push('/research')">
      {{$t('default.Research')}}
    </h1>
 </div>
  <!-- <div class="footer-item">
    <h1 >
      Solutions
    </h1>
    <p style="cursor: pointer" @click="$router.push('/wellness')">
      Wellness
    </p>
    <p style="cursor: pointer" @click="$router.push('/insurance')">
      Insurance
    </p>
    <p style="cursor: pointer" @click="$router.push('/healthcare_service')">
      Healthcare Service
    </p>
 </div> -->
  <div class="footer-item">
    <h1>
      {{$t('default.Contact')}}
    </h1>
    <p>retinalage2023@gmail.com
    </p>
 </div>
</el-row>
</div>
</div>


</template>


<script>
// @ is an alias to /src
import Home from './views/Home.vue'
const language = navigator.language || navigator.userLanguage;
console.log(language)
let lang = 'en'
if (language === 'zh-CN') {
  lang = 'chs'
}
export default {
  name: 'APp',
  components: {
    Home
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang') || lang,
      langMap: {
        chs: '简体中文',
        en: 'English'
      },
      height: 'auto',
      scale: 1,
      activeIndex: '1'
    }
  },
  mounted: function () {
    console.log(window.innerWidth)
    const app =  document.getElementById('app')
    console.log(app.offsetHeight)
    if (window.innerWidth < 1480) {
      this.scale = window.innerWidth /(1480)
      this.height = this.scale *app.offsetHeight
      console.log(this.height)
    }
  },
  methods: {
    changeLang (lang) {
      this.$i18n.locale = lang
      this.lang = lang
      localStorage.setItem('lang', lang)
      window.location.reload()
    },
  }
}
</script>

<style lang="less">
@font-face {
  font-family: 'iconfont';
  src: url('../assets/theme/fonts/iconfont.woff2?t=1638759009522') format('woff2'),
       url('../assets/theme/fonts/iconfont.woff?t=1638759009522') format('woff'),
       url('../assets/theme/fonts/iconfont.ttf?t=1638759009522') format('truetype');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*, body {
  margin: 0 0;
}
#app {
  min-width: 1480px;
  transform: scale(.5);
  transform-origin:0 0;
  .howh1 {
    font-size: 45px;
    margin: 60px auto 40px;
    font-weight: 500;
    color: #222222;
    letter-spacing: 5px;
    text-align: center;
  }
  .contact-btn { span {
    border-bottom: 0!important;
    }
  }
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
}
.el-menu--popup  {
  .el-button {
    margin: 0 0;
  }
}
.el-menu--horizontal .el-menu .el-menu-item, .el-menu--horizontal .el-menu .el-submenu__title {
  height: 50px!important;
}
.el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: none!important;
}
.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
  border-bottom: none!important;

}
.f-r {
  position: absolute;
  right: 10px;
  top: 15px;
}
  .sub-menu-item {
    font-size: 20px;
    // height: 60px;
    // line-height: 60px;;
  }
.header {
  .is-active  span {
    padding-bottom: 13px;
    border-bottom: 5px solid #409EFF
  }
  .menu-item,.el-menu-item {
    width: 200px;
    color: #000;
    font-size: 18px;
  }
  z-index: 500;
}
.nav {
  text-align: left;
  width: 800px;
  margin: 0 auto 20px;
}
.nav img {
  margin: 20px 0 0px;
}
.home-footer {
  padding: 50px 10px;
  width: 100%;
  height: 300px;
  color: #222222;
  background: #F6F9FF;
  .footer-item {
    width: 270px;
    display: inline-block;
    vertical-align: top;
    h1 {
      text-align: left;
      font-weight: 800;
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      color: #9DA6BB;
      margin: 10px 0;
      text-align: left;
    }
  }
  .footer-item:last-child {
    width: 100px;
  }
}

.uppercase {
  text-transform: uppercase;
}
</style>
