
export default {
  Home: 'Home',
  Platform: 'Platform',
  Technology: 'Technology',
  Research: 'Research',
  Contact: 'Contact',
  TheNewEasy1: 'The New and Easy',
  TheNewEasy2: 'Way to Measure',
  TheNewEasy3: 'Ageing risk',
  TheNewEasyText1: 'Accessible, technology-powered health status',
  TheNewEasyText2: 'monitoring for clinical decision making',
  HowRetinaAge: 'HOW RetinaAGE WORKS',
  Retinal: 'Retinal camera shooting',
  Upload: 'Upload retinal images',
  Artificial: 'Artificial intelligence analysis',
  RetinaAGE: 'RetinaAGE',
  report: 'report',
  RetinaAGEText1: 'RetinaAGE is an AI-powered system that aims to capture a snapshot of a person’s overall health through examination of the retina.',
  RetinaAGEText2: 'Trained on tens of thousands of retinal images, the AI system calculates a person’s biological age, or “Retinal age” with an accuracy ',
  RetinaAGEText22: 'meeting and even surpassing other established indicators of biological ageing.',
  RetinaAGEText3: 'RetinaAGE, the difference between "Retinal age" predicted by the AI system from retinal images and actual age, represents the deviation from the healthy trajectory.',
  HowRetinaAgeHelps: "HOW RetinaAGE HELPS",
  EnHance: 'Enhance understanding of ageing risk shared by end-users',
  Wellness: 'Wellness',
  HealthStatus: 'Health status',
  CardiovasularDisease: 'Cardiovasular disease',
  Parkinson: 'Parkinson`s disease',
  End_stage: 'End-stage kidney disease',
  Insurance: 'Insurance',
  Healthcare: 'Healthcare',
  WellnessProgram: 'Wellness Program',
  HealthMnitoring: 'Health Monitoring',
  NewCustomersOnboard: 'New customers onboard',
  PersonilzedService: 'Personalized service',
  RiskManagement: 'Risk management',
  TelehealthTelemedicine: 'Telehealth/Telemedicine',
  Preventivemedicine: 'Preventive medicine',
  Antiageingtrial: 'Anti-ageing trial',
  Continuousmonitoring: 'Continuous monitoring',
  Copyright: "Copyright  © 2022  RetinaAGE.com",
  AllRightReserved: 'All Rights Reserved.',
  HowToUse: 'How to use RetinaAGE',
  uploadFormart: '(Please upload raw images from fundus camera, supports JPEG, PNG, TIF, BMP format)',
  LeftEye: 'Left eye',
  RightEye: "Right eye",
  Gender: 'Gender',
  Male: 'Male',
  Female: 'Female',
  UploadFailed: 'Sorry, the images you uploaded can not pass the quality check.Please upload other images and try it again.',
  Age: 'Age',
  Readmore: 'Read more',
  GenerateReport: 'Generate report',
  EyesareWindow: 'Eyes are windows to your health',
  UplaodRetinalImages: 'Upload retinal images',
  ArtificialIntellgence: 'Artificial intellgence analysis',
  RetinaAGEreport: 'RetinaAGE Report',
  TheHumanEye: 'The Human Eye',
  TheHumanEyeText: 'Human eye is the only area of the human body that allows for the observation of tiny blood vessels and nerve tissues. The human eye provides a way to reflect the similar changes within human vital organs including the heart, brain and kidney. As such, the eyes are considered a window to overall health.',
  RetinalPhotographs: 'Retinal Photographs',
  RetinalPhotographsText: 'The retina is a layer of tissue that lines at the back of the eye. Retinal photography provides a color image of the retina. Nowadays, retinal photographs are most often used for eye disease diagnosis and clinical studies.',
  ArtificialIntelligence: 'Artificial Intelligence',
  ArtificialText: 'Artificial intelligence (AI) refers to systems that mimic human intelligence to perform tasks and can iteratively improve themselves based on the information they learned. Recent advances in the field of deep learning (DL) are enabling AI-based software to be trained on millions of retinal photographs allowing them for ageing assessment.',
  ResearchText: 'Get a close look at the performance reports of RetinaAge',
  Try: 'Try it',
  predicts: "RetinaAGE predicts mortality",
  predictsText1: '1. 19200 images trained and validated for AI model',
  predictsText2: '2. Each 1 year increase in the retinal age gap was associated with a 2% increase in risk of death',
  predictsText3: '3. Participants with highest retinal age gap had a 35% increased risk of mortality.',
  CVD: 'RetinaAGE predicts incident cardiovascular diseases (CVD)',
  CVDText1: '1.  Each 1 year increase in the retinal age gap was associated with a 1% increase in risk of CVD.',
  CVDText2: '2.  Participants with highest retinal age gap had a 16% increased risk of CVD.',
  PD: 'RetinaAGE predicts incident Parkinson’s diseases (PD)',
  PDText1: '1.  Each 1 year increase in the retinal age gap was associated with a 10% increase in risk of PD.',
  PDText2: '2.  Participants with highest retinal age gap had a 5-fold increased risk of PD.',
  ESKD: 'RetinaAGE predicts incident end-stage kidney diseases (ESKD)',
  ESKDText1: '1.  Each 1 year increase in the retinal age gap was associated with a 10% increase in risk of ESKD.',
  ESKDText2: '2.  Participants with highest retinal age gap had a 3-fold increased risk of ESKD.',
  Inquiryforcollaboration: 'Inquiry for collaboration',
  ContactText1: 'We intend to offer the RetinaAGE software as a research and educational tool free of charge to all researchers, with the condition that appropriate acknowledgment is given. We welcome collaboration and feedback from interested researchers, including batch processing of retinal images. Please contact us at retinalage2023@gmail.com for further information.',
  IfContact: 'If you decide to use our tool, kindly cite the following research papers:',
  Disclaimer: 'Disclaimer',
  DisclaimerText: 'We would like to remind all users that this website is solely intended for research purposes. As developers, we cannot be held responsibility for any consequences resulting from its use. ',
  genderPlaceholder: 'Please select gender',
  agePlaceholder: 'Please input your age',
  uploadRaw: 'upload raw images from fundus camera',
  reportInfo: 'RetinaAGE = Retinal age from fundus images - Actual age',
  reportID: 'Report ID',
  reportTitile: " Retina-based ageing risk (RetinaAGE) report",
}