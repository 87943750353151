<template>
  <div class="home">
    <div class="banner">
      <div class="banner-box">
        <h1>
          {{$t('default.TheNewEasy1')}}
        </h1>
        <h1>
          {{$t('default.TheNewEasy2')}}
        </h1>
        <h1>
          {{$t('default.TheNewEasy3')}}
        </h1>
        <p>
          {{$t('default.TheNewEasyText1')}}
        </p>
        <p>
          {{$t('default.TheNewEasyText2')}}
        </p>
        <el-button class="home-btn" @click="$router.push('/platform')" type="primary">{{$t('default.Try')}}</el-button>
      </div>
      <img class="background" src="@/assets/banner.png" alt="">
    </div>
    <div class="infos-box">
      <h1 class="howh1">
        {{$t('default.HowRetinaAge')}}
      </h1>
      <el-card  class="items-group">
        <div class="item odd" >
          <img src="@/assets/icon2.png" class="item-icon" alt="">
          <p>{{$t('default.Retinal')}}
          </p>
        </div>
        <div class="item">
          <img src="@/assets/icon2(2).png" class="item-icon" alt="">
          <p>
            {{$t('default.Upload')}}
          </p>
        </div>
        <div class="item odd">
          <img src="@/assets/icon2(1).png" class="item-icon" alt="">
          <p>
            {{$t('default.Artificial')}}
          </p>
        </div>
        <div class="item">
          <img src="@/assets/icon2(3).png" class="item-icon" alt="">
          <p>
            {{$t('default.report')}}
          </p>
        </div>
      </el-card >
      <p class="infos-p" style="margin-bottom: 0;">
        {{ $t('default.RetinaAGEText1') }}
      </p>
      <p class="infos-p" style="margin-bottom: 0;">
        {{ $t('default.RetinaAGEText2') }}
      </p>
      <p class="infos-p" style="margin-top: 0;">
        {{ $t('default.RetinaAGEText22') }}
      </p>
      <p  class="infos-p" style="margin-bottom: 0;">
        {{ $t('default.RetinaAGEText3') }}
      </p>
      <!-- <p  class="infos-p" style="margin-top: 0;">
        and actual age, represents the deviation from the healthy trajectory.
      </p> -->
    </div>
    <div class="report-info" style="position: relative">
      <img src="@/assets/item2.png" style="width: 80%" alt="">
      <div style="background: #F6F5F6;height: 80%;position: absolute; top: 10%;left: 0;width: 100%;z-index: -1">
      </div>
    </div>
    <h1 class=" howh1"  style="margin: 100px 0 20px;" >
      {{ $t('default.HowRetinaAgeHelps') }}
    </h1>
    <h4 class="uppercase" style="margin: 0 0;font-size: 30px;font-weight: 100">
      {{ $t('default.EnHance') }}</h4>
    <div class="home-tabs">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('default.Wellness')" name="Wellness">
          <div class="wellness-box">
            <el-card>
              <img src="@/assets/icon3-1.png" alt="">
              <p>{{ $t('default.WellnessProgram') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon3-2.png" alt="">
              <p>{{ $t('default.HealthMnitoring') }}</p>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('default.Insurance')" name="Insurance">
          <div class="wellness-box Insurance">
            <el-card>
              <img src="@/assets/icon4-1.png" alt="">
              <p>{{ $t('default.NewCustomersOnboard') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon4-1(1).png" alt="">
              <p>{{ $t('default.PersonilzedService') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon4-1(2).png" alt="">
              <p>{{ $t('default.RiskManagement') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon4-1(3).png" alt="">
              <p>{{ $t('default.WellnessProgram') }}</p>
            </el-card>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="$t('default.Healthcare')" name="Healthcare">
          <div class="wellness-box Insurance">
            <el-card>
              <img src="@/assets/icon5-1.png" alt="">
              <p>{{ $t('default.TelehealthTelemedicine') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon5-1(1).png" alt="">
              <p>{{ $t('default.Preventivemedicine') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon5-1(2).png" alt="">
              <p>{{ $t('default.Antiageingtrial') }}</p>
            </el-card>
            <el-card>
              <img src="@/assets/icon5-1(3).png" alt="">
              <p>{{ $t('default.Continuousmonitoring') }}</p>
            </el-card>
          </div>
        </el-tab-pane>
      </el-tabs>
      <img class="background" src="@/assets/earth.png" alt="">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {

  },
  data() {
    return {
      activeName: 'Wellness'
    }
  },
  methods: {
  }
}
</script>

<style lang="less">
.home {
  .banner {
    color: white;
    width: 100%;
    position: relative;
    top: -80px;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 30%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }

  }
  .Insurance {

    .el-card {
      width: 337px!important;
      height: 400px;
    }
  }
  .wellness-box {
    .el-card:last-child {
      margin-right: 0;
    }
    .el-card {
      display: inline-block;
      width: 500px;
      height: 400px;
      margin-right: 40px;
    }
  }
  .infos-box {
    width: 100%;
    .infos-p {
      font-size: 20px;
      margin: 40px 0;
    }
    h1 {
      font-size: 50px;
      margin: 60px auto 40px;
      color: #222222;
      letter-spacing: 5px;
      text-align: center;
    }
    .items-group {
      width: max-content;
      margin: 0 auto;
      .el-card__body {
        padding: 0 0;
      }
      .item {
        box-sizing: border-box;
        padding-top: 20px;
        vertical-align: top;
        display: inline-block;
        width: 351px;
        height: 296px;
        img {
          width: 50%;
        }
        p {
          font-size: 500;
          width: 180px;
          margin: 0 auto;
          font-size: 18px
        };
      }
      .odd {
        background: #4C84FF;
        color: #fff;
      }
    }
  }
  .home-tabs {
    width: 1480px;
    margin: 0 auto;
    position: relative;
    padding-top: 70px;
    text-align: center;
    .background {
      width: 50%;
      margin-left: -25%;
      left: 50%;
      position: absolute;
      z-index: -1;
      top: -45px;
    }
    .el-tabs__nav-wrap {
      margin: 0 0;
    }
    .el-tabs__nav  {
      // margin: 0 auto;
      float: none;
      height: 80px;
      padding-top: 20px;
    }
    .el-tabs__item {
      min-width: 220px;
      margin: 0 20px;
      height: 54px;
      line-height: 54px;
      font-size: 28px;
      text-align: center;
      padding: 0 0;
    }
    .el-tabs__active-bar {
      background: none;
    }
    .is-active {
      background: #4C84FF;
      border-radius: 50px;
      color: #fff;
      box-shadow: 0 5px 10px #4C84FF ;
    }
    .el-tabs__content {
      padding-bottom: 50px;
    }
    .el-tabs__nav-wrap::after {
      background:  none;;
    }
    img {
      margin: 50px auto 30px;
    }
    p {
      height: 30px;
      font-weight: 500;
      font-size: 22px;
      color: #222222;
      letter-spacing: 0.69px;
      text-align: center;
    }
  }
  .home-btn {
    width: 200px;
    font-weight: 800;
    border-radius: 50px;
    height: 50px;
    font-size: 23px;
    box-shadow: 0 10px 15px #333 ;
  }
  .more-btn {
    background: #4C84FF;
    margin-top: 60px;
    border-radius: 45px;
    font-size: 22px;
    box-shadow: 0 5px 8px #4C84FF ;
    min-width: 193px;
    height: 45px;

  }
}
</style>