<template>
  <div class="technology">
    <div class="banner">
      <div class="banner-box">
        <h1>
          {{$t('default.Technology')}}
        </h1>
        <p>
          {{$t('default.EyesareWindow')}}
        </p>
      </div>
      <img class="background" src="@/assets/banner_technology.png" alt="">
    </div>
    <div style="margin: 50px 0 ">
      <h1 class="howh1" style="margin: 10px 0 50px">
          {{$t('default.HowRetinaAge')}}</h1>
      <img class="step-img" src="@/assets/technology_steps.png" alt="">
      <p class="steps-info">
        <span>{{ $t('default.uploadRaw') }}</span>
        <span>{{ $t('default.Artificial') }}</span>
        <span>{{ $t('default.RetinaAGEreport') }}</span>
      </p>
    </div>
    <div class="infos-list">
      <div class="info-item">
        <div class="text-box">
          <h2>
          {{$t('default.TheHumanEye')}}
          </h2>
          <p>
          {{$t('default.TheHumanEyeText')}}
          </p>
        </div>
        <div class="img-box" style="text-align: right">
          <img src="@/assets/step_1.png" alt="">
        </div>
      </div>
      <div class="info-item" style="background:#ECEDF3">
        <div class="img-box">
          <img src="@/assets/step_2.png" alt="">
        </div>
        <div class="text-box">
          <h2>
          {{$t('default.RetinalPhotographs')}}
          </h2>
          <p>
          {{$t('default.RetinalPhotographsText')}}
          </p>
        </div>
      </div>
      <div class="info-item">
        <div class="text-box">
          <h2>
          {{$t('default.ArtificialIntelligence')}}
          </h2>
          <p>
          {{$t('default.ArtificialText')}}
          </p>
        </div>
        <div class="img-box" style="text-align: right">
          <img src="@/assets/step_3.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style lang="less">
.technology {
  padding: 0 0 100px;
    .steps-info {
      display: flex;
      position: relative;
      top: -50px;
      span {
        font-weight: 500;
        flex: 1
      }
    }
  .banner {
    color: white;
    width: 100%;
    position: relative;
    top: -80px;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 40%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }
  }
  .step-img {
    width: 80%
  }
  .infos-list {
    .info-item {
      padding: 0 10%;
      min-height: 450px;
      .text-box {
        text-align: left;
        display: inline-block;
        width: 40%;
        vertical-align: top;
        h2 {
          font-size: 30px;
          margin: 50px 0 50px;
        }
        p {
          font-size: 22px;
        }
      }
      .img-box {
        text-align: left;
        padding-top: 50px;
        display: inline-block;
        width: 50%;
        img {
          width: 65%
        }
      }
    }
  }
}
</style>