<template>
  <div class="healthcare">
    <div class="banner">
      <div class="banner-box">
        <h1>Revolutionize Healthcare<br />
        </h1>
        <h1>service delivery
        </h1>
      </div>
      <img class="background" src="@/assets/service_banner.png" alt="">
    </div>
    <div class="healthcare-body">
      <div class="healthcare-box">
        <div class="healthcare-item">
          <img class="icon" src="@/assets/healthcare_icon_1.png" alt="">
          <h3>Telehealth/Telemedicine remote management</h3>
          <p>
            <img src="@/assets/healthcare_icon_0.png" alt="">Retinal imaging analysis without needing eyecare specialist on-site .
          </p>
          <p>
            <img src="@/assets/healthcare_icon_0.png" alt="">Remoting monitoring of patient’s basic health status.
          </p>
        </div>
        <div class="healthcare-item">
          <img src="@/assets/healthcare_icon_2.png" alt=""  class="icon">
          <h3>Preventive medicine</h3>
          <p>
            <img src="@/assets/healthcare_icon_0.png" alt="">Early risk stratification and identification for better patient outcome and reduced healthcare cost.
          </p>
        </div>
        <div class="healthcare-item">
          <img src="@/assets/healthcare_icon_3.png" alt=""  class="icon">
          <h3>Anti-ageing trial</h3>
          <p>
            <img src="@/assets/healthcare_icon_0.png" alt="">Remote, real-time health statistic collection at your desired frequency.</p>
        </div>
        <div class="healthcare-item">
          <img src="@/assets/healthcare_icon_4.png" alt=""  class="icon">
          <h3>Continuous monitoring</h3>
          <p>
            <img src="@/assets/healthcare_icon_0.png" alt="">Easy, comprehensive tracking of patient’s health status over time, allowing tailoring of intervention strategies.
          </p>
        </div>
      </div>
      <div class="healthcare-img">
        <img src="@/assets/healthcare_item.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style lang="less">
.healthcare {
  padding: 0 0 100px;
  .wellness-box {
    .el-card:last-child {
      margin-right: 0;
    }
    .el-card {
      display: inline-block;
      width: 680px;
      height: 400px;
      margin-right: 40px;
    }
    img {
      margin: 50px auto 30px;
    }
    p {
      height: 30px;
      font-weight: 500;
      font-size: 22px;
      color: #222222;
      letter-spacing: 0.69px;
      text-align: center;
    }
  }
  .home-btn {
    width: 200px;
    font-weight: 800;
    border-radius: 50px;
    height: 50px;
    font-size: 23px;
    box-shadow: 0 10px 15px #333 ;
  }
  .banner {
    top: -80px;
    color: white;
    width: 100%;
    position: relative;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 40%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }
  }
  .healthcare-body {
    box-sizing: border-box;
    width: 1400px;
    margin: 20px auto;
    .healthcare-box {
      display: inline-block;
      vertical-align: top;
      width: 60%;
      .healthcare-item {
        margin-bottom: 100px;
        text-align: left;
        vertical-align: top;
        width: 50%;
        .icon {
          height: 80px;
        }
        display: inline-block;
        h3 {
          margin-top: 20px;
          height: 120px;
          font-size: 30px;
          width: 400px;
          font-weight: 400;
        }
        p {
          font-size: 20px;
          width: 400px;
          img {
            vertical-align: middle;
            position: relative;
            top: -2px;
            margin-right: 10px;
          }
        }
      }
    }
    .healthcare-img {
      display: inline-block;
      width: 30%
    }
  }
}
</style>