<template>
  <div class="platform">
    <div class="banner">
      <div class="banner-box">
        <h1>
          {{$t('default.Platform')}}
        </h1>
        <p>
          {{$t('default.HowToUse')}}
        </p>
      </div>
      <img class="background" src="@/assets/platform_banner.png" alt="">
    </div>
    <p style="margin-top: 0">
      {{$t('default.uploadFormart')}}
    </p>
    <div class="upload-box">
      <div class="upload-c" style="margin-right: 50px">
        <div class="upload-label upload-label-left">
          {{$t('default.LeftEye')}}
        </div>
        <el-upload
          class="upload-demo"
          list-type="picture-card"
          ref="upload"
          action=""
          accept="image/jpeg,image/jpg,image/png"
          :on-remove="handleRemove"
          v-loading="uploadLoading"
          :file-list="eyes['os'].fileList"
          :show-file-list="false"
          :on-change="fileChange('os')"
          :auto-upload="false"
          drag>
          <img :src="eyes.os.imageUrl" v-if="eyes.os.imageUrl" class="upload-img">
          <img v-else src="@/assets/os.png" alt=""  class="upload-img">
        </el-upload>
        <h3 style="text-align: left; margin: 20px 0 0">{{$t('default.Gender')}}:
          <el-select
          label="Gender"
          style="width: 200px;margin-left:20px; " v-model="gender"  :placeholder="$t('default.genderPlaceholder')">
            <el-option value="Male" :label="$t('default.Male')">
              {{$t('default.Male')}}
            </el-option>
            <el-option value="Female"  :label="$t('default.Female')">
              {{$t('default.Female')}}
            </el-option>
          </el-select> </h3>
      </div>
      <div class="upload-c">
        <div class="upload-label upload-label-right">
          {{$t('default.RightEye')}}
        </div>
        <el-upload
          class="upload-demo"
          ref="upload"
          action=""
          accept="image/jpeg,image/jpg,image/png"
          v-loading="uploadLoading"
          :on-remove="handleRemove"
          :file-list="eyes['od'].fileList"
          :show-file-list="false"
          :on-change="fileChange('od')"
          :auto-upload="false"
          drag>
          <img :src="eyes.od.imageUrl" v-if="eyes.od.imageUrl" class="upload-img">
          <img v-else src="@/assets/od.png" alt=""  class="upload-img">
        </el-upload>
        <h3 style="text-align: left; margin: 20px 0 0">
          {{$t('default.Age')}}:
          <el-input
          type="Number"
          label="Gender"
          maxlength="3"
          style="width: 200px;margin-left:20px;" v-model="age" :placeholder="$t('default.agePlaceholder')" /></h3>
      </div>
    </div>
    <div style="margin-top: 120px">
      <p v-if="failed" style=" color: red;font-size: 20px;">
        {{$t('default.UploadFailed')}}
      </p>
      <el-button  v-loading="uploadLoading" class="submit-btn"
      @click="uploadFile" type="primary">{{$t('default.GenerateReport')}}<img src="@/assets/arrow.png" alt=""></el-button>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import oss from '@/utils/oss'
import { mapState } from 'vuex'
import _ from 'lodash'
const sleep = async (ms) => new Promise((resolve, reject) => setTimeout(() => resolve(), ms))

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      age: null,
      gender: '',
      host: 'https://retinaage-files.oss-cn-shenzhen.aliyuncs.com',
      uploadLoading: false,
      failed: false,
      imageUrl: '',
      eyes: {
        od: {
          fileList: [],
          result: {},
          fileName: '',
          status: 'none',
          imageUrl: ''
        },
        os: {
          fileList: [],
          result: {},
          fileName: '',
          imageUrl: '',
          status: 'none'
        },
      },
      fileName: '',
      fieList: [],
      totalTime: 0
    };
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  methods: {
    randomNum (minNum,maxNum) {
      switch(arguments.length){
        case 1:
            return parseInt(Math.random()*minNum+1,10);
        break;
        case 2:
            return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10);
        break;
        default:
            return 0;
        break;
      }
    },
    fileChange (eye) {
      return async (file) => {
        console.log(file)
        this.eyes[eye].imageUrl = URL.createObjectURL(file.raw);
        this.eyes[eye].fileList = [file]
        this.$store.commit('SET_FUNDUS', {
          fundus: this.eyes
        })
        // this.uploadFile(file, eye)
        return
      }
    },
    handleRemove () {
      this.imageUrl = ''
    },
    async uploadFile() {
      this.age = Number(this.age)
      const eyes = ['od', 'os']
      if (this.eyes['os'].fileList.length === 0 && this.eyes['od'].fileList.length === 0) {
        return alert(`Please select fundus photo`)
      }
      if (!this.age && this.age !== 0) {
        return alert(`Please input your age`)
      }
      if (typeof this.age !== 'number' && this.age !== NaN) {
        return alert(`Age must be a number`)
      }
      for (let i = 0; i <eyes.length; i++) {
        const eye = eyes[i]
        const fundus = this.eyes[eye]
        if (fundus.fileList.length === 0) {
          continue;
        }
        const file = fundus.fileList[0]
        let reader = new FileReader();
        let rs = reader.readAsArrayBuffer(file.raw);
        console.log(file)
        let blob = null;
        reader.onload = async (e) => {
          if (typeof e.target.result === 'object') {
            blob = new Blob([e.target.result])
          } else {
            blob = e.target.result
          }
          await this.uploadblob(blob, file, eye)
        }
      }
    },
    async uploadblob (blob, file, eye) {
      this.uploadLoading = true
      this.failed = false
      const id = new Date().getTime()
      try {
        const exName = file.name.split('.')[1]
        let path = `input/${id}.${exName}`
        let sts = await oss.getSTS()
        const result = await oss.putBlob(sts.Credentials)(blob, path)
        console.log(JSON.stringify(result))
        this.eyes[eye].imageUrl = result.url
        if (exName === 'tif' || exName === 'tiff') {
          this.eyes[eye].imageUrl = `${this.eyes[eye].imageUrl}?x-oss-process=style/tif2png`
        }
        this.eyes[eye].fileName = result.name
        this.$nextTick(() => {
          this.getResult(this.eyes[eye].fileName, eye)
        })
      } catch (err) {
        console.log(err)
        this.imageUrl = ''
        this.$notify({
          title: 'Upload failed, please try again',
          type: 'error'
        })
      }
    },
    async getResult (filename, eye) {
      let name = filename.split('.')[0]
      let rename = name.replace('input', 'output')
      const host = this.host
      let resultJson = `${host}/${rename}.json`
      let ageResult = null
      try {
        ageResult = await axios.get(resultJson)
      } catch (err) {
        // 在这里轮询
        await sleep(1000)
        return this.getResult(filename, eye)
      }
      const { result } = ageResult.data
      this.eyes[eye].result = result
      this.eyes[eye].status = 'succeed'
      this.eyes.age = 'failed'
      this.$store.commit('SET_FUNDUS', {
        fundus: this.eyes
      })
      if (this.eyes['od'].fileList.length > 0 && this.eyes['os'].fileList.length === 0) {
        let totalAge = 'failed'
        if (this.eyes.od.result && this.eyes['od'].status === 'succeed') {
          totalAge = this.eyes.od.result
        }
        if (totalAge === 'Reject' || totalAge === 'failed') {
          this.uploadLoading = false
          return this.failed = true
        }
        if (totalAge !== 'failed' && totalAge !== 'Reject' ) {
          totalAge = Math.floor(totalAge)
        }
        console.log(totalAge)
        this.eyes.age = totalAge
        this.$store.commit('SET_FUNDUS', {
          fundus: this.eyes
        })
        this.uploadLoading = false
        this.$router.push({
          path: '/report',
          query: {
            age: this.age,
            gender: this.gender
          }
        })
      }
      else if (this.eyes['os'].fileList.length > 0 && this.eyes['od'].fileList.length === 0) {
        let totalAge = 'failed'
        if (this.eyes.os.result && this.eyes['os'].status === 'succeed') {
          totalAge = this.eyes.os.result
        }
        if (totalAge === 'Reject' || totalAge === 'failed') {
          this.uploadLoading = false
          return this.failed = true
        }
        if (totalAge !== 'failed' && totalAge !== 'Reject' ) {
          totalAge = Math.floor(totalAge)
        }
        this.eyes.age = totalAge
        this.$store.commit('SET_FUNDUS', {
          fundus: this.eyes
        })
        this.uploadLoading = false
        this.$router.push({
          path: '/report',
          query: {
            age: this.age,
            gender: this.gender
          }
        })
      } else if (this.eyes['os'].fileList.length > 0 && this.eyes['os'].fileList.length > 0) {
      if (this.eyes['od'].status === 'succeed' && this.eyes['os'].status === 'succeed') {
          let index = 0
          let totalAge = 0
          if (this.eyes.od.result && this.eyes.od.result !== 'failed' && this.eyes.od.result !== 'Reject') {
            index ++
            totalAge = totalAge + Number(this.eyes.od.result)
          }
          if (this.eyes.os.result && this.eyes.os.result !== 'failed' && this.eyes.os.result !== 'Reject') {
            index ++
            totalAge = totalAge + Number(this.eyes.os.result)
          }
          if ((this.eyes.os.result == 'Reject' && this.eyes.od.result == 'Reject') ||
            (this.eyes.os.result == 'failed' && this.eyes.od.result == 'failed')
          ) {
             this.uploadLoading = false
             return this.failed = true
          }

          if (index == 0) {
            totalAge ='failed'
          }
          if (totalAge !== 'failed' ) {
            totalAge = Math.floor(totalAge / index)
          }
          this.eyes.age = totalAge
          this.$store.commit('SET_FUNDUS', {
            fundus: this.eyes
          })
          this.uploadLoading = false
          this.$router.push({
            path: '/report',
            query: {
              age: this.age,
              gender: this.gender
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less">
.platform {
  padding: 0 0 100px;
  .banner {
    color: white;
    width: 100%;
    position: relative;
    top: -80px;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 40%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }
  }
  .upload-box {
    margin-top: 50px;
    vertical-align: top;
    .upload-c {
      position: relative;
      vertical-align: top;
      display: inline-block;
      width: 550px;
      height: 400px;;
      .upload-label {
        position: absolute;
        background: #4C84FF;
        color: white;
        height: 50px;
        line-height: 50px;
        z-index: 100;
        font-size: 20px;
        width: 140px;

        top: 0;
      }
      .upload-label-left {
        left: 0;
        border-bottom-right-radius: 20px;
      }
      .upload-label-right {
        right: 0;
        border-bottom-left-radius: 20px;
      }
      .upload-demo {
        height: 100%;
        width: 100%;
      }
      .el-upload {
        height: 100%;
        width: 100%;
      }
      .el-upload--picture-card {
        border: none;
      }
      .el-upload-dragger {
        border-radius: 20px;
        border-top-left-radius: 0;
        border: 5px solid #4C84FF;
        width: 100%;
        height: 100%;
      }
    }
  }
  .upload-img {
    width: 100%;
  }
  .submit-btn {
    width: 800px;
    background: #4C84FF;
    margin-top: 60px;
    border-radius: 60px;
    font-size: 22px;
    box-shadow: 0 5px 8px #4C84FF ;
    height: 55px;
    img {
      width: 20px;
      vertical-align: top;
      margin-left: 20px;
    }
  }
}
</style>