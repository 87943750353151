import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Platform from '../views/Platform.vue'
import Wellness from '../views/Wellness.vue'
import Insurance from '../views/Insurance.vue'
import Technology from '../views/Technology.vue'
import Research from '../views/Research.vue'
import Report from '../views/Report.vue'
import Contact from '../views/Contact.vue'
import HealthcareService from '../views/HealthcareService.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/platform',
    name: 'Platform',
    component: Platform
  },
  {
    path: '/technology',
    name: 'Technology',
    component: Technology
  },
  {
    path: '/wellness',
    name: 'Wellness',
    component: Wellness
  },
  {
    path: '/report',
    name: 'Report',
    component: Report
  },
  {
    path: '/insurance',
    name: 'Insurance',
    component: Insurance
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/research',
    name: 'Research',
    component: Research
  },
  {
    path: '/healthcare_service',
    name: 'HealthcareService',
    component: HealthcareService
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
