export default {
  Home: '主页',
  Platform: '平台',
  Technology: '技术',
  Research: '研究',
  Contact: '联系我们',
  TheNewEasy1: '创新、简便的衰老风险测量方法',
  TheNewEasy2: '',
  TheNewEasy3: '',
  TheNewEasyText1: '用于临床决策的技术驱动的健康状态监测系统',
  TheNewEasyText2: '',
  HowRetinaAge: '如何使用视网膜年龄预测网站',
  Retinal: '视网膜照相机拍照',
  Upload: '上传视网膜图像',
  Artificial: '人工智能分析 ',
  RetinaAGE: 'RetinaAGE',
  report: '视网膜年龄报告',
  RetinaAGEText1: '视网膜年龄网站是一个由人工智能驱动的系统，旨在通过视网膜图像信息捕获个体全身健康的快照。  ',
  RetinaAGEText2: '该人工智能系统经过成千上万张视网膜图像的训练，能够计算出一个人的生物年龄，或者说“视网膜年龄”，',
  RetinaAGEText22: '其准确度达到甚至超过其他已建立的生物衰老指标。  ',
  RetinaAGEText3: '视网膜年龄差，由人工智能系统根据视网膜图像预测的“视网膜年龄”与实际年龄之间的差异，代表了与健康衰老轨迹的偏离。',
  HowRetinaAgeHelps: "视网膜年龄可帮助",
  EnHance: '提升终端用户共享的衰老风险认知',
  Wellness: '健康',
  HealthStatus: '健康状况',
  CardiovasularDisease: '心血管系统疾病',
  Parkinson: '帕金森病',
  End_stage: '终末期肾病',
  Insurance: '保险 ',
  Healthcare: '医疗保健',
  WellnessProgram: '健康计划',
  HealthMnitoring: '健康监测',
  NewCustomersOnboard: '新客户入驻',
  PersonilzedService: '个性化服务',
  RiskManagement: '风险管理',
  TelehealthTelemedicine: '远程健康/远程医疗',
  Preventivemedicine: '预防医学',
  Antiageingtrial: '抗衰老试验',
  Continuousmonitoring: '持续监测',
  Copyright: "版权所有 © 2022 RetinaAGE.com",
  AllRightReserved: '保留所有权利。',
  HowToUse: '如何使用视网膜年龄网站',
  uploadFormart: '(请上传来自眼底相机的原始图像，支持 JPEG、PNG、TIF、BMP 格式)',
  LeftEye: '左眼',
  RightEye: "右眼",
  Gender: '性别',
  Male: '男',
  Female: '女',
  UploadFailed: '对不起，您上传的图片未能通过质量检测。请上传其他图片并再次尝试。',
  Age: '年龄',
  Readmore: '阅读更多',
  GenerateReport: '报告生成',
  EyesareWindow: '眼睛是人体健康的窗口',
  UplaodRetinalImages: ' 上传视网膜图像',
  ArtificialIntellgence: '人工智能分析',
  RetinaAGEreport: '视网膜年龄报告',
  TheHumanEye: '人类眼睛',
  TheHumanEyeText: '人类眼睛是人体唯一可以观察到微小血管和神经组织的部位。人眼提供了一种反映心脏、大脑和肾脏等人体重要器官内部变化的方式。因此，眼睛被认为是整体健康的窗口。',
  RetinalPhotographs: '视网膜照片',
  RetinalPhotographsText: '视网膜是位于眼球后部的一层组织。视网膜摄影提供了视网膜的彩色图像。如今，视网膜照片最常用于眼病诊断和临床研究。  ',
  ArtificialIntelligence: '人工智能',
  ArtificialText: '人工智能（AI）指的是模仿人类智能以执行任务，并能基于它们所学到的信息进行迭代自我改善的系统。深度学习（DL）领域的最新进展正使得基于AI的软件能够在数百万张视网膜照片上进行训练，以进行衰老评估。',
  ResearchText: '深入了解视网膜年龄的表现',
  Try: '开始体验',
  predicts: "视网膜年龄差预测死亡风险",
  predictsText1: '1.AI模型接受了19200张图像的训练和验证',
  predictsText2: '2.视网膜年龄差每增加1年，死亡风险增加2%',
  predictsText3: '3.视网膜年龄差最高的四分位数的参与者，其死亡风险增加35%',
  CVD: '视网膜年龄差预测心血管疾病（CVD）的发生 ',
  CVDText1: '1.视网膜年龄差每增加1年，心血管疾病的风险增加1%。',
  CVDText2: '2.视网膜年龄差最高的四分位数的参与者，其心血管疾病的风险增加16%。',
  PD: '视网膜年龄差预测帕金森病（PD）的发生',
  PDText1: '1. 视网膜年龄差每增加1年，帕金森病的风险增加10%。',
  PDText2: '2. 视网膜年龄差最高的四分位数的参与者，其帕金森病的风险增加5倍。',
  ESKD: '视网膜年龄差预测终末期肾病（ESKD）的发生',
  ESKDText1: '1.视网膜年龄差每增加1年，终末期肾病的风险增加10%。',
  ESKDText2: '2.视网膜年龄差最高的四分位数的参与者，其终末期肾病的风险增加3倍。',
  Inquiryforcollaboration: '合作咨询',
  ContactText1: '我们将免费向所有研究人员提供视网膜年龄软件作为研究和教育工具，条件是给予适当的认可和引用。我们欢迎感兴趣的研究者进行合作和反馈，包括批量处理视网膜图像。请通过retinalage2023@gmail.com与我们联系以获取更多信息。  ',
  IfContact: '如果您决定使用我们的工具，请引用以下研究论文：',
  Disclaimer: '免责声明',
  DisclaimerText: '我们想提醒所有用户，此网站仅用于研究目的。作为开发者，我们不能对其使用的任何后果承担责任。 ',
  genderPlaceholder: '请选择性别',
  agePlaceholder: '请输入您的年龄',
  uploadRaw: '上传视网膜图像 ',
  reportInfo: '视网膜年龄差 = 视网膜年龄 - 实际年龄',
  reportID: '报告 ID',
  recordDate: '记录日期',
  reportTitile: "视网膜年龄报告",
}
