import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/plugins/axios'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
window.Vue = Vue
const language = navigator.language || navigator.userLanguage;
let lang1 = 'en'
if (language === 'zh-CN') {
  lang1 = 'chs'
}
const lang = localStorage.getItem('lang') || lang1
const i18n = new VueI18n({
  locale: lang,
  messages: {
    chs: require('../i18n/chs.js'),
    en: require('../i18n/en.js'),
  }
})


import locale from 'element-ui/lib/locale/lang/en'
Vue.config.productionTip = false
Vue.use(ElementUI, { locale })

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
