<template>
  <div class="research">
    <div class="banner">
      <div class="banner-box">
        <h1>
          {{$t('default.Research')}}
        </h1>
        <p>
          {{$t('default.ResearchText')}}
        </p>
        <el-button class="home-btn" @click="$router.push('/platform')" type="primary">{{$t('default.Try')}}</el-button>
      </div>
      <img class="background" src="@/assets/banner_research.png" alt="">
    </div>
    <div class="infos-list">
      <div class="info-item">
        <div class="img-box">
          <img src="@/assets/research_item_1.png" alt="">
          <a target="_blank" href='https://bjo.bmj.com/content/107/4/547.long'>
            <el-button class="submit-btn" type="primary">{{ $t('default.Readmore') }}<img src="@/assets/arrow.png" alt=""></el-button>
          </a>
        </div>
        <div class="text-box">
          <h2>
          {{$t('default.predicts')}}
          </h2>
          <h3></h3>
          <div class="text-item">
            <img src="@/assets/research_iton_4.png"   alt="">
            <p>
          {{$t('default.predictsText1')}}
            </p>
          </div>
          <div class="text-item">
            <img src="@/assets/research_iton_0.png"   alt="">
            <p>
          {{$t('default.predictsText2')}}
            </p>
          </div>
          <div class="text-item">
            <img src="@/assets/research_iton_1.png"   alt="">
            <p>
            {{$t('default.predictsText3')}}
            </p>
          </div>
        </div>
      </div>
      <div class="info-item background-1" >
        <div class="text-box">
          <h2>
          {{$t('default.CVD')}}
          </h2>
          <h3></h3>
          <div class="text-item">
            <img src="@/assets/research_iton_3.png"   alt="">
            <p>
          {{$t('default.CVDText1')}}
             <br>
            <br>
          {{$t('default.CVDText2')}}
            </p>
          </div>
        </div>
        <div class="img-box">
          <img src="@/assets/research_item_2.png" alt="">
          <a target="_blank" href='https://www.ahajournals.org/doi/full/10.1161/STROKEAHA.122.038809?rfr_dat=cr_pub++0pubmed&url_ver=Z39.88-2003&rfr_id=ori%3Arid%3Acrossref.org'>
            <el-button class="submit-btn" type="primary">{{ $t('default.Readmore') }}<img src="@/assets/arrow.png" alt=""></el-button>
          </a>
        </div>
      </div>
      <div class="info-item">
        <div class="img-box">
          <img src="@/assets/research_item_3.png" alt="">
          <a target="_blank" href='https://academic.oup.com/ageing/article/51/3/afac062/6555657?login=true'>
            <el-button class="submit-btn" type="primary">{{ $t('default.Readmore') }}<img src="@/assets/arrow.png" alt=""></el-button>
          </a>
        </div>
        <div class="text-box">
          <h2>
          {{$t('default.PD')}}
          </h2>
          <h3></h3>
          <div class="text-item">
            <img src="@/assets/research_iton_5.png"   alt="">
            <p>
              {{$t('default.PDText1')}}
            <br>
            <br>
              {{$t('default.PDText2')}}
            </p>
          </div>
        </div>
      </div>
      <div class="info-item background-2">
        <div class="text-box">
          <h2>
          {{$t('default.ESKD')}}
          </h2>
          <h3></h3>
          <div class="text-item">
            <img src="@/assets/research_iton_6.png"   alt="">
            <p>
            {{$t('default.ESKDText1')}}
            <br>
            <br>
            {{$t('default.ESKDText2')}}
            </p>
          </div>
        </div>
        <div class="img-box">
          <img src="@/assets/research_item_4.png" alt="">
          <a target="_blank" href='https://www.ajkd.org/article/S0272-6386(22)01047-2/fulltext'>
            <el-button class="submit-btn" type="primary">{{ $t('default.Readmore') }}<img src="@/assets/arrow.png" alt=""></el-button>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
    };
  },
}
</script>

<style lang="less">
.research {
  padding: 0 0;
  .home-btn {
    width: 200px;
    font-weight: 800;
    border-radius: 50px;
    height: 50px;
    font-size: 23px;
    box-shadow: 0 10px 15px #333 ;
  }
  .banner {
    top: -80px;
    color: white;
    width: 100%;
    position: relative;
    .banner-box {
      text-align: left;
      left: 10%;
      top: 40%;
      z-index: 200;
      position: absolute;
      h1 {
        font-size: 45px;
        text-align: left;
      }
      p {
        text-align: left;
        font-family: 'Regular';
        font-size: 20px;
        color: #FFFFFF;
      }
      button {
        margin-top: 20px;
      }
    }
    .pc {
      width: 500px;
      right: 10%;
      position: absolute;
      bottom: -5%;
      z-index: 200;
    }
    .background {
      width: 100%;
    }
  }
  .step-img {
    width: 80%
  }
  .infos-list {
    margin-top: -80px;
    .background-1 {
      background-image: url('../../assets/research_background_1.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .background-2 {
      background-image: url('../../assets/research_background_2.png');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    .info-item {
      padding: 50px 10%;
      min-height: 450px;
      .text-box {
        text-align: left;
        display: inline-block;
        width: 50%;
        vertical-align: top;
        h2 {
          font-size: 33px;
          font-weight: 400;
          padding-left: 30px;
          margin: 80px 0 5px;
        }
        h3 {
          width: 50px;
          height: 5px;;
          background: #F87C3F;
          margin-left: 30px;
          margin-bottom: 5px;;
        }
        p {
          line-height: 40px;
          font-size: 22px;
        }
        .text-item {
          display: flex;
          vertical-align: top;
          .icon-box {
            width: 90px;
            height: 90px;
            text-align: center;
            background: #F2F2F2;
            border-radius: 10px;
            border: 5px solid #fff;
            box-sizing: border-box;
            box-shadow: 10px 10px 15px #ddd ;
            img {
              width: 100%;
            }
          }
          img {
            display: inline-block;
            width: 150px;
            height: 150px;
            vertical-align: top;
          }
          p {
            display: inline-block;
            vertical-align: top;
            flex: 1;
            margin-top: 35px;;
          }
        }
      }
      .img-box {
        text-align: left;
        padding-top: 50px;
        display: inline-block;
        width: 50%;
        .submit-btn {
          width: 220px;
          background: #4C84FF;
          margin-left: 200px;
          margin-top: 0px;
          border-radius: 60px;
          font-size: 22px;
          box-shadow: 0 5px 8px #4C84FF ;
          height: 50px;
          img {
            width: 20px;
            vertical-align: top;
            margin-left: 20px;
          }
        }
        img {
          width: 100%
        }
      }
    }
  }
}
</style>