<template>
  <div class="report">
    <div class="report-body">
    <h1>{{$t('default.reportTitile')}}
    </h1>
    <div class="report-info">
      <div class="info-box">
        <div class="info-item">
          <span class="label">{{$t('default.reportID')}}:</span> <span class="underline">{{reportId}}</span>
        </div>
      </div>
      <div class="info-box">
        <div class="info-item">
          <span class="label">{{$t('default.Gender')}}:</span> <span class="underline">
            {{$route.query.gender === 'Male' ? $t('default.Male') : $t('default.Female')
            }}</span>
        </div>
        <div class="info-item">
          <span  class="label">{{$t('default.Age')}}:</span> <span class="underline">{{$route.query.age}}</span>
        </div>
      </div>
      <div class="info-box">
        <div class="info-item">
          <span class="label">{{$t('default.recordDate')}}:</span> <span class="underline">{{ getDate()}}</span>
        </div>
      </div>
    </div>

    <div class="eye-box">
      <div class="eye-c" style="margin-right: 50px">
        <div class="eye-label eye-label-left">
          {{ $t('default.LeftEye') }}
        </div>
          <img :src="fundus.os.imageUrl" alt=""  class="eye-img">
      </div>
      <div class="eye-c">
        <div class="eye-label eye-label-right">
          {{ $t('default.RightEye') }}
        </div>
          <img :src="fundus.od.imageUrl" alt=""  class="eye-img">
      </div>
    </div>
    <div class="result">
      <h2 style="font-size: 30px;font-weight: 500;color: #4C84FF;">
        {{$t('default.reportTitile')}}
      </h2>
      <div class="dashboard">
        <img src="@/assets/dashboard.png" alt="">
        <h2 class="years">
          <span style="font-size: 50px">
            {{fundus.age - $route.query.age}}
          </span>
          <span>
            years
          </span>
        </h2>
        <!-- <img class="arrow" :style="`transform: rotate(${fundus.age / 100 * 265 -132.5}deg)`" src="@/assets/report-arrow.png" alt=""> -->
        <!-- <el-progress :width="500" :stroke-width="30" :show-text="false" type="dashboard" :percentage="fundus.age" :color="colors"></el-progress> -->
      </div>
      <h2 style="color: #000;margin-top: 80px; font-size: 30px;font-weight: 500;">
        <p style="margin-bottom: 10px">
          {{ $t('default.reportInfo') }}
        </p>
      </h2>
      <div v-if="lang === 'chs'">
        <p style="color:rgb(76, 132, 255)">
          视网膜年龄差每增加一年
        </p>
        <div class="footer-box">
          <div class="footer-item">
            <img class="icon" src="@/assets/fei.png" alt="" />
            <p>
              心血管风险增加1%
              <img class="up" src="@/assets/up.png" alt="" />
            </p>
          </div>
          <div class="footer-item">
            <img class="icon" src="@/assets/nao.png" alt="" />
            <p>
              帕金森疾病风险增加10%
              <img class="up" src="@/assets/up.png" alt="" />
            </p>
          </div>
          <div class="footer-item">
            <img class="icon" src="@/assets/shen.png" alt="" />
            <p>
              终未期肾病风险增加10%
              <img class="up" src="@/assets/up.png" alt="" />
            </p>
          </div>
        </div>
        <p class="info">
          *此报告仅供临床参考，具体诊断和治疗需到专业医疗机构进一步明确
        </p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'
const language = navigator.language || navigator.userLanguage;
let lang = 'en'
if (language === 'zh-CN') {
  lang = 'chs'
}
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      lang: window.localStorage.getItem('lang') || lang,
      reportId: new Date().getTime(),
      age: '',
      recordingDate: '',
      gender: '',
      years: 0,
      colors: [
        {color: '#2875E4', percentage: 20},
        {color: '#2875E4', percentage: 40},
        {color: '#AD7AF4', percentage: 60},
        {color: '#AD7AF4', percentage: 80},
        {color: '#AD7AF4', percentage: 100}
      ]
    };
  },
  computed: mapState({
    fundus: state => state.fundus.fundus
  }),
  methods: {
    getDate () {
      return moment().format('YYYY-MM-DD HH:mm')
    }
  }
}
</script>

<style lang="less">
.report {
  margin: 0 auto;
  background: #F4F6FC;
  .info {
    color: red;
    margin-top: 100px;
  }
  .footer-box {
    display: flex;
    width: 800px;
    margin: 0 auto;
    margin-top: 20px;
    .footer-item {
      flex: 1;
      .icon {
        width: 100px;
        margin-bottom: 20px;
      }
      p {
        .up {
          display: inline-block;
          width: 20px;
          position: relative;
          top: 5px;
        }
      }
    }
  }
  .report-body {
    padding-top: 50px;
    padding-bottom: 200px;;
    margin: 0 auto;
    width: 1200px;
  }
  .underline {
    border-bottom: 1px solid #eee;;
    flex: 1;
  }
  h1 {
    color: #4856B7;
    margin: 0 auto 50px;
  }
  h2 {
    color: #4856B7;
    margin: 50px auto 50px;
    font-size: 25px;;
  }
  .eye-box {
    margin-top: 50px;
    vertical-align: top;
    .eye-c {
      position: relative;
      vertical-align: top;
      display: inline-block;
      width: 550px;
      height: 500px;
      background: #000;
      line-height: 500px;
      overflow: hidden;
      border: 1px solid #eee;
      border-radius: 20px;
      .eye-label {
        position: absolute;
        background: #4C84FF;
        color: white;
        height: 50px;
        line-height: 50px;
        z-index: 100;
        font-size: 20px;
        width: 140px;

        top: 0;
      }
      .eye-label-left {
        left: 0;
        border-bottom-right-radius: 20px;
      }
      .eye-label-right {
        right: 0;
        border-bottom-left-radius: 20px;
      }
    }
  }
  .eye-img {
    vertical-align: middle;
    width: 100%;
  }
  .dashboard {
    margin: 0 auto;
    text-align: center;
    width: 400px;
    h2 {
      span {
        color: #fff;
        display: block;
        height: 50px;
      }
      width: 350px;
      height: 350px;
      position: relative;
      top: 25px;
      border-radius: 50%;
      line-height: 350px;
      display: block;
      color: #fff;
      background: #4C84FF;
    }
    .arrow {
      transform-origin: 50% 84%;
      width: 50px;
      left: 50%;

      top: 100px;
      margin-left: -25px;
    }
    img {
      position: absolute;
      // bottom: 50px;
      left: 0;
      top: 0;
      width: 100%;
    }
    position: relative;
  }
  .report-info {
    .info-box {
      display: flex;
      .info-item {
        height: 50px;
        margin: 10px 0;
        font-size: 22px;
        flex: 1;
        display: flex;
        span {
          text-align: left;
          display: inline-block;
        }
        .label {
          width: auto;;
          padding-right: 20px;
        }
      }
    }
  }
}
</style>